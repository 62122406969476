import React, { Dispatch, SetStateAction } from 'react'
interface IChatInputProps {
    placeholder: string,
    className?: string,
    disabled?: boolean,
    onInput: Dispatch<SetStateAction<string>>,
    inputValue : string
}
function ChatInput({placeholder, className, disabled=false, onInput, inputValue=''}:IChatInputProps) {
    function handleInput(e:any){
        onInput(e.target.value);
    }
  return (
    <input onInput={handleInput} value={inputValue} disabled={disabled} className={` bottom-0 w-full p-5 md:p3 text-slate-800 outline-none ${className} ${disabled ? 'opacity-50' : ''}`} type="text" placeholder={placeholder}/>
  )
}

export default ChatInput