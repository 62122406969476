/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Message, { EMessageType } from "../../utils/message/message";
import { EUserType } from "../../utils/message/message";
import "./chat.scss";
import TypingLoader from "../../utils/typing-loader/typingLoader";
import ChatInput from "../../utils/inputs/chat-input/chatInput";
import { ReactComponent as Send } from "../../assets/icons/mail-send.svg";
import {
  EBotResponseTypes,
  getMessagesArray,
  uniqueId,
  wait,
} from "../../helpers/helpers";
import {
  addDoc,
  collection,
  db,
  signInWithGoogle,
} from "../../firebase/config";
import { doc, getDocs, orderBy, query, updateDoc } from "firebase/firestore";
import Button from "../../utils/button/button";
interface IChatProps {
  chatOpen: boolean;
  setChatOpen: (s: boolean) => void;
}

interface IChatHistory {
  name: string[];
  mail: string[];
  message: string[];
  confirm: string[];
  after: string[];
}

enum EAdminCommands {
  access = "access chats",
  name = "arv",
  mail = "arvndvv@gmail.com",
}

function Chat(props: IChatProps) {
  const [typing, setTyping] = React.useState<boolean>(false);
  const [lockInput, setLockInput] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [chatRef, setChatRef] = useState<any>();
  const emptyHistory = {
    name: [],
    mail: [],
    message: [],
    confirm: [],
    after: [],
  };
  const [chatHistory, setChatHistory] = useState<IChatHistory[]>([]);
  const [currentStep, setcurrentStep] = useState(0);
  const [historyIndex, setHistoryIndex] = useState(0);

  const checkExistingChat = async () => {
    const existingId = localStorage.getItem("chatHistoryId") || "";
    const existRef = existingId && (await doc(db, "chatHistory", existingId));
    return existRef;
  };

  const addNewDoc = async () => {
    const existRef = await checkExistingChat();
    if (existRef) {
      setChatRef(existRef);
      return;
    }
    const date_created = new Date();
    const ref = collection(db, "chatHistory");
    const docRef = await addDoc(ref, {
      date_created,
    });
    localStorage.setItem("chatHistoryId", docRef?.id);
    addNewDoc();
  };

  const updateFirestore = async () => {
    try {
      const docRef = chatRef;
      if (chatHistory && chatHistory.length > 0 && docRef) {
        const name = chatHistory[chatHistory.length - 1].name[0];
        const mail = chatHistory[chatHistory.length - 1].mail[0];
        const message = chatHistory[chatHistory.length - 1].message[0];
        const date_modified = new Date();

        updateDoc(docRef, {
          name,
          mail,
          message,
          chatHistory,
          date_modified,
        });
      }
    } catch (e) {
      console.error("something went wrong with sending messages!", e);
      showError("Something went wrong with sending messages!");
      showError("Please try after sometime, or try hard refresh");
      sendAlternate();
    }
  };

  const updateHistory = (text: string) => {
    switch (currentStep) {
      case 0:
        setChatHistory((prev) => {
          const currentHistory = prev[historyIndex];
          const update = {
            ...currentHistory,
            name: [...currentHistory.name, text],
          };
          const newHistory = prev.map((item, index) => {
            if (index === historyIndex) {
              return update;
            }
            return item;
          });
          return newHistory;
        });
        break;
      case 1:
        setChatHistory((prev) => {
          const currentHistory = prev[historyIndex];
          const update = {
            ...currentHistory,
            mail: [...currentHistory.mail, text],
          };
          const newHistory = prev.map((item, index) => {
            if (index === historyIndex) {
              return update;
            }
            return item;
          });
          return newHistory;
        });
        break;
      case 2:
        setChatHistory((prev) => {
          const currentHistory = prev[historyIndex];
          const update = {
            ...currentHistory,
            message: [...currentHistory.message, text],
          };
          const newHistory = prev.map((item, index) => {
            if (index === historyIndex) {
              return update;
            }
            return item;
          });
          return newHistory;
        });
        break;
      case 3:
        setChatHistory((prev) => {
          const currentHistory = prev[historyIndex];
          const update = {
            ...currentHistory,
            confirm: [...currentHistory.confirm, text],
          };
          const newHistory = prev.map((item, index) => {
            if (index === historyIndex) {
              return update;
            }
            return item;
          });
          return newHistory;
        });
        break;
      default:
        setChatHistory((prev) => {
          const currentHistory = prev[historyIndex];
          const update = {
            ...currentHistory,
            after: [...currentHistory.after, text],
          };
          const newHistory = prev.map((item, index) => {
            if (index === historyIndex) {
              return update;
            }
            return item;
          });
          return newHistory;
        });
        break;
    }
  };

  const cleanState = () => {
    setTyping(false);
    setIsAdmin(false);
    setMessages([]);
    setInputValue("");
    setLockInput(false);
  };

  useEffect(() => {
    const chatHistory = localStorage.getItem("chatHistory");
    if (chatHistory && chatHistory.length) {
      const currentHistoryIndex = localStorage.getItem("currentHistoryIndex");
      setChatHistory(JSON.parse(chatHistory));
      setHistoryIndex(Number(currentHistoryIndex) + 1);
    } else {
      setHistoryIndex(0);
    }
    cleanState();
  }, []);

  const manualHardClean = () => {
    setHistoryIndex(historyIndex + 1);
    cleanState();
  };

  useEffect(() => {
    localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
    const index = chatHistory.length - 1;
    localStorage.setItem("currentHistoryIndex", index.toString());
    if (chatHistory.length >= 30) {
      const trim = chatHistory.slice(15);
      setChatHistory(trim);
      return;
    }
    if (chatHistory.length % 4 === 0) {
      if (currentStep === 3) {
        updateFirestore();
      }
    }
  }, [chatHistory]);

  useEffect(() => {
    if (chatHistory[historyIndex] === undefined) {
      setChatHistory((prev) => [...prev, emptyHistory]);
      setcurrentStep(0);
    }
  }, [historyIndex]);

  useEffect(() => {
    scrollToBotton();
  }, [messages]);

  useEffect(() => {
    if (currentStep <= 3) {
      typeBotQuestions();
    }
  }, [currentStep]);

  const scrollToBotton = () => {
    const chatBody = document.getElementById("chatBody");
    if (chatBody) {
      chatBody.scrollTop = chatBody.scrollHeight;
    }
  };

  const pushMessage = (
    text: string,
    utype: EUserType = EUserType.bot,
    messageType?: EMessageType,
    hideFooter = false
  ) => {
    const key = uniqueId();
    const element = (
      <Message
        message={text}
        userType={utype}
        key={key}
        mtype={messageType}
        hideFooter={hideFooter}
      />
    );
    setMessages((prev: any) => {
      return [...prev, element] as any;
    });
  };
  const sendAlternate = () => {
    pushMessage(
      "Alternately, you can contact me through mail.",
      EUserType.bot,
      EMessageType.success
    );
    pushMessage("Mail ID: mail@arvnd.in", EUserType.bot, EMessageType.success);
  };
  const showChats = () => {
    //fetch all docuemnts from collection chatHistory
    const docRef = getDocs(
      query(collection(db, "chatHistory"), orderBy("date_modified", "asc"))
    );
    docRef.then((querySnapshot) => {
      const total = querySnapshot.size;
      let chatNumber = 0;
      pushMessage(
        `Total chats ${total}`,
        EUserType.bot,
        EMessageType.success,
        true
      );
      querySnapshot.forEach((doc) => {
        pushMessage(
          `Showing chat no: ${++chatNumber}`,
          EUserType.bot,
          EMessageType.success,
          true
        );
        const data = doc.data();
        const formatedDate = data.date_modified
          ? new Date(data.date_modified.seconds * 1000).toLocaleString()
          : "";
        pushMessage(
          `Date: ${formatedDate}`,
          EUserType.bot,
          EMessageType.normal,
          true
        );
        pushMessage(
          `Name: ${data.name || ""}`,
          EUserType.bot,
          EMessageType.normal,
          true
        );
        pushMessage(
          `Mail: ${data.mail || ""}`,
          EUserType.bot,
          EMessageType.normal,
          true
        );
        pushMessage(
          `Message: ${data.message || ""}`,
          EUserType.bot,
          EMessageType.normal,
          true
        );
      });
    });
  };
  const handleAdminAuth = async () => {
    try {
      const authUser = await signInWithGoogle();
      const userMail = authUser?.user?.email;
      if (userMail === EAdminCommands.mail) {
        pushMessage("Welcome Admin", EUserType.bot, EMessageType.success);
        setIsAdmin(true);
        setLockInput(true);
        showChats();
        return;
      }
      pushMessage(
        "Looks like you are an imposter",
        EUserType.bot,
        EMessageType.error
      );
      setLockInput(true);
      await wait(1000);
      props.setChatOpen(false);
    } catch (e) {
      console.error("Looks like you are an imposter");
      pushMessage(
        "Looks like you are an imposter",
        EUserType.bot,
        EMessageType.error
      );
      setLockInput(true);
      await wait(1000);
      props.setChatOpen(false);
    }
  };
  const Auth = (key: any) => {
    const element = (
      <Button key={key} onClick={handleAdminAuth}>
        verify
      </Button>
    );
    return element;
  };
  const pushAuth = () => {
    const key = uniqueId();
    const element = <Auth key={key} />;
    setMessages((prev: any) => {
      return [...prev, element] as any;
    });
  };

  const typeBotResponse = async (i = 0) => {
    let confirmStep = false;
    let confrimStepAnswerIsNo = false;
    let messagesToPush: any[] = [];
    if (currentStep < 3) {
      messagesToPush = getMessagesArray(
        currentStep,
        EBotResponseTypes.response
      );
    }
    if (currentStep === 3) {
      confirmStep = true;
      if (inputValue.toLowerCase() === "yes") {
        messagesToPush = getMessagesArray(0, EBotResponseTypes.ending_yes);
      } else {
        confrimStepAnswerIsNo = true;
        messagesToPush = getMessagesArray(0, EBotResponseTypes.ending_no);
      }
    }
    if (i <= messagesToPush.length - 1) {
      if (currentStep > 3) return;
      holdUser();
      await wait(2000);
      setTyping(false);
      pushMessage(messagesToPush[i]);
      setLockInput(false);
      await typeBotResponse(++i);
    } else {
      if (currentStep <= 3) {
        if (confirmStep) {
          if (confrimStepAnswerIsNo) {
            holdUser();
            await wait(2000);
            manualHardClean();
            return;
          } else {
            updateFirestore();
          }
        }
      }
      setcurrentStep((prev) => prev + 1);
    }
  };

  const typeBotQuestions = async (i = 0) => {
    const messagesToPush = getMessagesArray(
      currentStep,
      EBotResponseTypes.questions
    );
    if (i <= messagesToPush.length - 1) {
      holdUser();
      await wait(2000);
      setTyping(false);
      pushMessage(messagesToPush[i]);
      setLockInput(false);
      typeBotQuestions(++i);
    } else {
      return;
    }
  };

  const holdUser = () => {
    setLockInput(true);
    setTyping(true);
  };
  const unholdUser = () => {
    setLockInput(false);
    setTyping(false);
  };
  const showWarning = (text: string) => {
    pushMessage(text, EUserType.bot, EMessageType.warning);
    setInputValue("");
    unholdUser();
  };
  const showError = (text: string) => {
    pushMessage(text, EUserType.bot, EMessageType.error);
    setInputValue("");
    unholdUser();
  };
  const handleAdmin = async () => {
    if (inputValue.toLowerCase() === EAdminCommands.access) {
      pushMessage("What is your name?");
      updateHistory(inputValue);
      setInputValue("");
    } else if (inputValue.toLowerCase() === EAdminCommands.name) {
      pushMessage("Ahaa, you are here, please verify yourself");
      updateHistory(inputValue);
      setInputValue("");
      pushAuth();
      return;
    } else {
      pushMessage(
        "Smart! but please show some ethics!",
        EUserType.bot,
        EMessageType.error
      );
      updateHistory(inputValue);
      setInputValue("");
      manualHardClean();
    }
  };
  const handleSend = async (e: any) => {
    e.preventDefault();
    if (isAdmin || inputValue.toLowerCase() === EAdminCommands.access) {
      if (!isAdmin) {
        setIsAdmin(true);
      }
      handleAdmin();
      return;
    }
    if (!isAdmin && inputValue) {
      holdUser();
      if (currentStep === 1) {
        // eslint-disable-next-line no-useless-escape
        const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          inputValue
        );
        if (!validEmail) {
          showWarning("Please enter a valid email");
          return;
        }
        addNewDoc();
      }
      pushMessage(inputValue, EUserType.user);
      updateHistory(inputValue);
      setInputValue("");
      if (currentStep <= 3) {
        await typeBotResponse();
      } else {
        unholdUser();
      }
    }
  };
  return (
    <div className={`chat relative ${!props.chatOpen ? "closed" : ""}`}>
      <div className="header flex justify-between items-center px-5 py-2 shadow-md  shadow-subtle">
        <h2 className="text-2xl text-slate-600 font-bold">Chat</h2>
        <button
          className="absolute top-1 right-1 text-lg md:text-sm w-8 h-8 md:w-5 md:h-5 bg-slate-400 rounded-full text-center flex items-center justify-center cursor-pointer hover:bg-primary-light"
          onClick={() => props.setChatOpen(false)}
        >
          _
        </button>
      </div>
      <div
        id="chatBody"
        className="chat__body relative flex flex-col gap-2 pt-5 px-5 overflow-y-auto w-full h-[75%]"
      >
        {messages}
      </div>
      {typing ? <TypingLoader className="!left-4 !bottom-17" /> : ""}
      <form
        className="input  shadow-top flex row-auto absolute bottom-0 w-full rounded-b-md overflow-hidden"
        onSubmit={handleSend}
      >
        <ChatInput
          disabled={lockInput}
          inputValue={inputValue}
          onInput={setInputValue}
          className="!relative  dis flex w-[85%]"
          placeholder={lockInput ? "wait..." : "Type Here"}
        />
        <button
          type="submit"
          className={`btn--send float-right bg-slate-400 w-[15%] flex justify-center items-center cursor-pointer hover:bg-slate-300 transition-all duration-300 ease-in-out ${
            lockInput ? "opacity-30 pointer-events-none" : ""
          }`}
        >
          <Send />
        </button>
      </form>
    </div>
  );
}

export default Chat;
