import React from "react";
import recommendationsData from "../../data/recommendations.json";
import "./recommendations.styles.scss";
import Button from "../../utils/button/button";

export default function Recommendations() {
  return (
    <div className="recommendations">
      <h2 className="heading">
        Words of <br />
        Recommendations
      </h2>
      <div className="recommendations__container">
        {recommendationsData.map((recommendation, index) => (
          <div className="recommendation" data-index={index} key={index}>
            <blockquote
              cite="https://www.linkedin.com/in/arvndvv/details/recommendations/"
              className="recommendation__message"
            >
              <p>" &nbsp;{recommendation.msg} &nbsp;"</p>
            </blockquote>
            <div className="recommendation__details">
              <div className="recommendation__author">
                <h4 className="recommendation__author__name">
                  {recommendation.name}
                </h4>
                <h6 className="recommendation__author__designation">
                  {recommendation.designation}
                </h6>
                <small className="recommendation__author__company">
                  <a
                    target="_blank"
                    href={recommendation.company.link}
                    rel="noreferrer"
                  >
                    {recommendation.company.name}
                  </a>
                </small>
              </div>
              <div className="recommendation__source">
                <a
                  href="https://www.linkedin.com/in/arvndvv/details/recommendations/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button type="normal">Source</Button>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
